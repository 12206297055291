import React from "react";
import Layout from "../../components/layout"

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
            <h1>Thanks for reaching out!</h1>
        </div>
      </div>
    </section>
  </Layout>
);